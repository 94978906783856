import { useState, useEffect, useCallback } from "react"

/**
 **
 *
 * usage in code
 * const variant = useVariant("redesignPdpTest")
 * returns value from VWO variant
 *
 *----------------------------------------------------------
 *
 * usage in VWO
 * var event = new CustomEvent("redesignPdpTest", {
 *  detail: {variant: "a"}
 * });
 * window.redesignPdpTest = "a";
 * window.dispatchEvent(event);
 *
 **
 */
export const useVariant = (
  type = "redesignPdpTest",
  defaultValue = "control",
  once = true
) => {
  const [variant, setVariant] = useState(() =>
    typeof window !== "undefined" && window[type] ? window[type] : defaultValue
  )

  const handler = useCallback(
    (event) => {
      setVariant(event?.detail?.variant || defaultValue)
    },
    [defaultValue]
  )

  useEffect(() => {
    // Check for pre-existing value on mount
    if (typeof window !== "undefined" && window[type]) {
      setVariant(window[type])
      //exit if value found in window
      if (once) {
        return
      }
    }
    //if value not found OR once is false -> addEventListener
    window.addEventListener(type, handler, { once })
    return () => {
      window.removeEventListener(type, handler)
    }
  }, [type, handler])

  return variant
}
